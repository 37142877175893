import { createBrowserRouter, Navigate } from "react-router-dom";
import { PageLayout } from "@/core/layout/PageLayout/PageLayout.tsx";

const BASE_URL = import.meta.env.VITE_BASE_URL;

export const appRouter = createBrowserRouter(
    [
        {
            path: "/",
            Component: PageLayout,
            // TODO: add Error boundary
            children: [
                { path: "/", lazy: () => import("@/core/pages/HomePage/HomePage.tsx") },
                { path: "/homepage", element: <Navigate replace to="/" /> },
                { path: "/login", element: <Navigate replace to="/" /> },
                {
                    path: "/reports",
                    lazy: () => import("@/features/reports/pages/ReportOverviewPage/ReportOverviewPage.tsx"),
                },
                {
                    path: "/analysis",
                    lazy: () => import("../../features/evaluations/page/EvaluationPage.tsx"),
                },
                {
                    path: "/administration",
                    lazy: () => import("@/core/pages/AdminPage/AdminPage.tsx"),
                },
                {
                    path: "/reports/*",
                    children: [
                        {
                            path: "import",
                            lazy: () =>
                                import("../../features/importReport/pages/ImportReportPage/ImportReportPage.tsx"),
                        },
                        {
                            path: "create",
                            lazy: () => import("@/features/reports/pages/CreateReportPage/CreateReportPage.tsx"),
                        },
                        {
                            path: "edit/:reportId/*",
                            lazy: () =>
                                import("@/features/reports/layout/EditReportPageLayout/EditReportPageLayout.tsx"),
                            children: [
                                {
                                    path: "step-1",
                                    lazy: () =>
                                        import("@/features/reports/pages/ReportStepOnePage/ReportStepOnePage.tsx"),
                                },
                                {
                                    path: "step-2",
                                    lazy: () =>
                                        import("@/features/reports/pages/ReportStepTwoPage/ReportStepTwoPage.tsx"),
                                },
                                {
                                    path: "step-3",
                                    lazy: () =>
                                        import("@/features/reports/pages/ReportStepThreePage/ReportStepThreePage.tsx"),
                                },
                                {
                                    path: "step-4",
                                    lazy: () =>
                                        import("@/features/reports/pages/ReportStepFourPage/ReportStepFourPage.tsx"),
                                },
                                {
                                    path: "step-5",
                                    lazy: () =>
                                        import("@/features/reports/pages/ReportSummaryPage/ReportSummaryPage.tsx"),
                                },
                                { path: "*", element: <Navigate replace to="step-1" /> },
                            ],
                        },
                        { path: "*", element: <Navigate replace to="/" /> },
                    ],
                },
                {
                    path: "/impressum",
                    lazy: () => import("@/core/pages/ImpressumPage/ImpressumPage.tsx"),
                },
                {
                    path: "/privacy",
                    lazy: () => import("@/core/pages/PrivacyPage/PrivacyPage.tsx"),
                },
                {
                    path: "/accessibility",
                    lazy: () => import("@/core/pages/AccessibilityPage/AccessibilityPage.tsx"),
                },
                {
                    path: "*",
                    lazy: () => import("@/core/pages/NotFoundPage/NotFoundPage.tsx"),
                },
            ],
        },
    ],
    { basename: BASE_URL },
);
